import { ActionType } from 'redux-promise-middleware';
import {
    ACTION_LOAD_DATA,
    ACTION_SAVE_DATA,
    ACTION_DELETE_DATA
} from '../actionTypes';

const initialState = {

};


const appReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case `${ACTION_LOAD_DATA}_${ActionType.Fulfilled}`:
        case `${ACTION_SAVE_DATA}_${ActionType.Fulfilled}`:
        case `${ACTION_DELETE_DATA}_${ActionType.Fulfilled}`:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default appReducer;
