import React, { FC } from "react";
import rightImgDemo from "images/dendelions.jpg";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Image from "../Image";
import { useTranslation } from "react-i18next";
import Link from "components/Link";
import { useNavigate } from "react-router-dom";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const OurMissions: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  const { t, i18n } = useTranslation();
  // const navigate = useNavigate();
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:mr-10 lg:flex-row items-center  ${className}`}
    >
      <div className="flex-grow">
        <Image
          alt="hero"
          sizes="(max-width: 768px) 100vw, 50vw"
          src={rightImg}
        />
      </div>
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:ml-10 lg:w-2/5">
        <span className="text-xs uppercase tracking-wider font-medium text-neutral-400">
          OUR MISSION
        </span>
        <h2 className="font-semibold text-3xl sm:text-4xl mt-3">
          Together, We Can Help The Lonely Who In Dark
        </h2>
        <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
          Our mission is develop efficient and easy use App to help who live along who needs help, especially Senior in emergency. To achieve this, we needs all kinds of support. especially finical support. This website is a free website for every one to use, if you feel it good, please donate us, if you have any idea or find a bug , please contact us
        </span>
        <ButtonPrimary onClick={() => window.open('https://crutch.world/donate/', '_blank')} className="mt-8"> Please Donate  </ButtonPrimary>
      </div>

    </div>
  );
};

export default OurMissions;
