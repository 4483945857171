import React, { FC } from "react";
import imgAdsDef from "images/ads.png";
import Image from "../Image";
// import { Adsense } from '@ctrl/react-adsense';
export interface SectionAdsProps {
  className?: string;
  imgAds?: string;
}

const SectionAds: FC<SectionAdsProps> = ({
  className = "",
  imgAds = imgAdsDef,
}) => {
  return (
    <div></div>
  )
  // return (

  //   <Adsense
  //     client="ca-pub-1320414067884126"
  //     slot="6839485257"
  //     style={{ display: 'block' }}
  //     layout="in-article"
  //     format="fluid"

  //   />

  // );
};

export default SectionAds;
