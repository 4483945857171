import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import React, { FC, useEffect, useState } from "react";
import SingleTitle from "./SingleTitle";
import PostMeta2 from "components/PostMeta2/PostMeta2";
import SingleMetaAction2 from "./SingleMetaAction2";
import NcImage from "components/NcImage/NcImage";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAppByName, installApp } from "../../redux/actionCreators/appActionCreators";
import { PostDataType } from "data/types";
import { AppDataTypeResponse, PostDataTypeResponse } from "models/appCategory";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { toast } from "react-toastify";

export interface SingleHeader4Props {
  hiddenDesc?: boolean;
  className?: string;
  app?: PostDataType
}

const SingleHeader4: FC<SingleHeader4Props> = ({
  hiddenDesc = false,
  className = "",
  app
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  console.log('..app..', app);
  const [installed, setInstalled] = useState(false)
  const currentUser = useSelector((state: any) => state.auth)
  const onClickSubmit = () => {
    dispatch(installApp(app?.id, currentUser.user.user_uuid)).then((resp: any) => {
      toast.success(t("common:app_installed"))
      navigate("/dashboard/app-installed")
    })
  }
  return (
    <>
      <div className={`nc-SingleHeader4 ${className}`}>
        <div className="max-w-5xl mx-auto space-y-5 dark">
          {/* <CategoryBadgeList
            itemClass="!px-3"
            categories={[DEMO_CATEGORIES[1]]}
          /> */}
          <SingleTitle
            mainClass="text-neutral-900 font-bold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl 2xl:text-6xl dark:text-neutral-100"
            title={app ? app?.title : ''}
          />
          {!hiddenDesc && (
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
              {app?.desc}
            </span>
          )}
        </div>

        {/* FEATURED IMAGE */}
        <NcImage
          alt="post"
          containerClassName="my-10 relative"
          className="object-cover w-full rounded-lg shadow-xl"
          src={app?.featuredImage}
          width={1260}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />

        <div className=" space-y-10">
          <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
            {
              (app?.installed && app?.installed > 0) || installed ?
                <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                  {t("common:appInstalled")} </h2>
                :
                <ButtonPrimary onClick={onClickSubmit} type="submit">
                  {t("common:install")}
                </ButtonPrimary>
            }
            <SingleMetaAction2 />
          </div>
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </div>
    </>
  );
};

export default SingleHeader4;
