import Image from "components/Image";
import { PostDataType } from "data/types";
import React, { FC } from "react";
export interface SingleContentProps {
  appData?: PostDataType
}
const SingleContentDemo: FC<SingleContentProps> = ({
  appData
}) => {
  return (
    <>
      {/* THIS IS THE DEMP CONTENT - YOU CAN USE MARKDOWN, JSON ...*/}
      <p>
        {
          appData?.fullDescription
        }
      </p>

    </>
  );
};

export default SingleContentDemo;
