import { AppData } from 'models/appCategory';
import { SERVICE_URL } from '../../constants/variables';
import {
    ACTION_LOAD_DATA,
    ACTION_SAVE_DATA,
    ACTION_DELETE_DATA
} from '../actionTypes';

export const getAppCategories = (): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/categories`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    console.log('..get App Categories.. response data..' + JSON.stringify(responseData));
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const getGames = (type = 'latest'): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/games?type=` + type, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const getAppsByCategoryName = (categoryName: string, page: number, limit: number): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/categories/${categoryName}?page=` + page + '&limit=' + limit, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};


export const saveAppData = (appData: AppData): any => {

    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/app`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(appData),
                method: 'POST',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const getApps = (type: string): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/apps?type=` + type + '&appOrGame=2', {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const getAppsByDeveloper = (userUuid: string | null, userName: string): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/apps?userUuid=` + userUuid + '&userName=' + userName, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};
export const searchApp = (keyword: string): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;

        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/apps?keyword=` + keyword, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};
export const getAppByName = (appName: string, userUuid: string): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/app/` + appName + '?userUuid=' + userUuid, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};
export const getTags = (page: number, limit: number): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/tags?page=` + page + '&limit=' + limit, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const saveComments = (appId: any, userUuid: string, comment: string, rating: number): any => {

    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_SAVE_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/comments/${appId}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userUuid,
                    comment,
                    rating
                }),
                method: 'POST',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const getDevelopers = (page: number, limit: number): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/developers?page=` + page + '&limit=' + limit, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const getDeveloperByUserName = (userName: string): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/developer/${userName}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const getTrendingCategories = (): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/trending-categories`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};
export const installApp = (appId: any, userUuid: string): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_SAVE_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/app/install`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    appId,
                    userUuid,
                }),
                method: 'POST',
            })
                .then((r) => r.json())
                .then((responseData) => {
                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
}

export const getMyInstalledApp = (userUuid: string, page: number, limit: number): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/current/app-installed?userUuid=` + userUuid + "&page=" + page + "&limit=" + limit, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};

export const getMySubmittedApp = (userUuid: string, page: number, limit: number): any => {
    return (dispatch: any, getState: any) => {
        const token = getState().auth.jwt;
        return dispatch({
            type: ACTION_LOAD_DATA,
            payload: fetch(`${SERVICE_URL}/h5-app-store/current/app-submitted?userUuid=` + userUuid + "&page=" + page + "&limit=" + limit, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })
                .then((r) => r.json())
                .then((responseData) => {

                    if (responseData.statusCode >= 300) {
                        return Promise.reject(responseData);
                    } else {
                        return responseData;
                    }
                }),
        });
    };
};