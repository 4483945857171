import React from "react";
import Logo from "components/Logo/Logo";
import SocialsList1 from "components/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';
export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Our Community",
    menus: [
      { href: "https://crutch.world/about/", label: "About Us" },
      { href: "https://crutch.world/our-mission/", label: "Our Mission" },
    ],
  },
  {
    id: "1",
    title: "Volunteer",
    menus: [
      { href: "https://crutch.world/volunteer/", label: "Volunteer Need" },
      { href: "https://crutch.world/donate/", label: "Donate" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "https://crutch.world/", label: "Crutch World" },
      { href: "https://qr-unify.h5app.world/", label: "QR Unify" },
    ],
  },
  {
    id: "4",
    title: "Privacy",
    menus: [
      { href: "https://crutch.world/privacy-policy/", label: "Privacy Policy" },
      { href: "https://crutch.world/terms-conditions/", label: "Terms and Conditions" },
    ],
  },
];

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  if (isMobile) {
    return <div className="nc-Footer relative py-16 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
    </div>

  } else {
    return (
      <div className="nc-Footer relative py-16 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
        <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
          <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
            <div className="col-span-2 md:col-span-1">
              <Logo />
            </div>
            <div className="col-span-2 flex items-center md:col-span-3">
              {/* <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}
            </div>
          </div>

          {widgetMenus.map(renderWidgetMenuItem)}
        </div>
        <div className="flex items-center text-center">
          <p style={{ "margin": "0 auto", "paddingTop": "20px" }}>
            Every senior need a crutch and every younger can be a crutch <br />
            &copy; {t("common:copy_right")}
          </p>
        </div>
      </div>
    );
  }
};

export default Footer;
