import React, { useEffect, useState } from "react";
import becomAuthorImg from "images/BecomeAnAuthorImg.png";
import { DEMO_POSTS, DEMO_POSTS_VIDEO } from "data/posts";
import { DEMO_AUTHORS } from "data/authors";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { PaginationResult, PostDataType, TaxonomyType } from "data/types";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionHero2 from "components/Sections/SectionHero2";
import SectionTrending from "components/Sections/SectionTrending";
import { Route } from "routers/types";
import SectionMagazine6 from "components/Sections/SectionMagazine6";
import SectionVideos from "components/Sections/SectionVideos";
import SectionAds from "components/Sections/SectionAds";
import SectionGridPosts from "components/Sections/SectionGridPosts";
import { PostDataTypeResponse, PostDataWithPagingResponse } from "models/appCategory";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getApps, getMyInstalledApp } from "../../../redux/actionCreators/appActionCreators";
import OurMission from "components/OurMissions/OurMissions";
import { isMobile } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Grid, Pagination } from 'swiper/modules';
import { DEMO_CATEGORIES } from "data/taxonomies";
import CardCategory1 from "components/CardCategory1/CardCategory1";
import CardCategory2 from "components/CardCategory2/CardCategory2";
import CardMobileApp from "components/CardMobileApp/CardMobileApp";

const PageHomeDemo4: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [hotestApps, setHotestApps] = useState<PostDataType[]>([]);
  const [latestApps, setLatestApps] = useState<PaginationResult>();
  const currentUser = useSelector((state: any) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [installedApps, setInstalledApps] = useState<PostDataType[]>([]);
  useEffect(() => {
    dispatch(getApps('hotest'))
      .then((apps: PostDataWithPagingResponse) => {
        console.log('..Apps..', apps.value)
        setHotestApps(apps.value.data);
      });
    dispatch(getApps('latest'))
      .then((apps: PostDataWithPagingResponse) => {
        console.log('..Apps..', apps.value)
        setLatestApps(apps.value);
      });
    if (currentUser && currentUser.user && isMobile) {
      dispatch(getMyInstalledApp(currentUser.user.user_uuid, 0, 1000))
        .then((apps: PostDataWithPagingResponse) => {
          console.log('..app OR games..', apps.value)
          setInstalledApps(apps.value.data);
          setCurrentPage(apps.value.currentPage);
          setTotalPage(apps.value.totalPages);
          if (apps.value.totalPages > 1) {
            // const pageData: CustomLink[] = [];
            // for (let page = 1; page < apps.value.totalPages; page++) {
            //   pageData.push({
            //     label: "" + page,
            //     href: "/",
            //   })
            // }

          }
        });
    }
  }, []);
  if (isMobile && currentUser && currentUser.user) {
    return <> <Swiper
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
      {
        // installedApps.map(item2 => <>
        <SwiperSlide>{
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-6 md:gap-8">
            {installedApps.map((item, i) => (
              <CardMobileApp

                key={item.id}
                taxonomy={item}
              />
            ))}
          </div>
        }</SwiperSlide>
        // </>

        // )
      }
    </Swiper>
    </>
  } else {

    return (
      <div className="nc-PageHomeDemo4 relative">
        <div className="relative">
          {/* <SectionHero2 /> */}
          <div className="relative overflow-hidden">
            <div className="container relative">
              <SectionTrending
                heading=""
                className="py-16 lg:py-28"
                posts={hotestApps}
              />
              {/* <div className="relative py-16">
              <BackgroundSection />
              <SectionSliderNewCategories
                heading="Subtopics of Travel"
                subHeading="Discover 286,833 beautiful places to go"
                categories={TRAVEL_SUBCATS}
              />
            </div> */}

              {/* <SectionMagazine6
              className="py-16 lg:py-28"
              heading="🧩 Editor Picks"
            /> */}

              <div className="relative py-16">
                <BackgroundSection />
                <SectionSliderNewAuthors
                  heading={t("common:newest_developer")}
                  subHeading="Say hello to future creator potentials"
                  authors={DEMO_AUTHORS.filter((_, i) => i < 10)}
                />
              </div>

              {/* <SectionSubscribe2 className="py-16 lg:py-28" /> */}

              <SectionAds />

              {/* <SectionVideos className="py-16 lg:py-28" /> */}

              {/* <div className="relative py-16">
              <BackgroundSection />
              <SectionGridPosts
                headingIsCenter
                postCardName="card10V2"
                heading="Explore latest video articles"
                subHeading="Hover on the post card and preview video 🥡"
                posts={DEMO_POSTS_VIDEO.filter((_, i) => i > 5 && i < 12)}
                gridClass="sm:grid-cols-2 lg:grid-cols-3"
              />
            </div> */}

              <SectionBecomeAnAuthor
                className="py-16 lg:py-28"
                rightImg={becomAuthorImg}
              />
            </div>

            <div className="dark bg-neutral-800 dark:bg-black dark:bg-opacity-20 text-neutral-100">
              <div className="relative container">
                <SectionGridPosts
                  className="py-16 lg:py-28"
                  postCardName="card11"
                  heading="Explore latest H5 Apps"
                  subHeading=""
                  posts={latestApps?.data}
                  // pagination = {latestApps}
                  gridClass="sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 lg:gap-8"
                />
              </div>
            </div>
            <div className="dark bg-neutral-800 dark:bg-black dark:bg-opacity-20 text-neutral-100">
              <div className="relative container">
                < OurMission className="py-16 lg:py-28" />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
};

export default PageHomeDemo4;
