// Auth
export const SET_AUTH = 'SET_AUTH';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_CHANGE_PASSWORD_ALT = 'AUTH_CHANGE_PASSWORD_ALT';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_VERIFY = 'AUTH_RESET_PASSWORD_VERIFY';
export const AUTH_FETCH_CURRENT_USER = 'AUTH_FETCH_CURRENT_USER';
export const REGISTRATION_VERIFY_ACCOUNT = 'REGISTRATION_VERIFY_ACCOUNT';
export const RESEND_EMAIL_VERIFICATION_CODE = 'RESEND_EMAIL_VERIFICATION_CODE';
export const CREATE_USER = 'CREATE_USER';

export const ACTION_LOAD_DATA = 'ACTION_LOAD_DATA';
export const ACTION_SAVE_DATA = 'ACTION_SAVE_DATA';
export const ACTION_DELETE_DATA = 'ACTION_DELETE_DATA';