import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import { PostAuthorType } from "data/types";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import MySlider from "components/MySlider";
import { getDevelopers } from "../../redux/actionCreators/appActionCreators";
import { useDispatch } from "react-redux";
import { PostAuthorTypePaginationResponse, PostAuthorTypeResponse } from "models/appCategory";
export interface SectionSliderNewAuthorsProps {
  className?: string;
  heading: string;
  subHeading: string;
  authors: PostAuthorType[];
  itemPerRow?: number;
}

const SectionSliderNewAuthors: FC<SectionSliderNewAuthorsProps> = ({
  heading = "Suggestions for discovery",
  subHeading = "Popular places to recommends for you",
  className = "",
  authors,
  itemPerRow = 5,
}) => {
  const dispatch = useDispatch();
  const [author, setAuthor] = useState<PostAuthorType[]>([]);
  useEffect(() => {
    dispatch(getDevelopers(0, 25))
      .then((authorResp: PostAuthorTypePaginationResponse) => {
        console.log('..author  Resp..', authorResp.value)
        setAuthor(authorResp.value.data)
      });

  }, []);
  return (
    <div className={`nc-SectionSliderNewAuthors ${className}`}>
      <Heading desc={subHeading} isCenter>
        {heading}
      </Heading>
      {author.length > 0 ? <MySlider
        itemPerRow={itemPerRow}
        data={author}
        renderItem={(item, index) => (
          <CardAuthorBox2 key={index} author={item} />
        )}

      /> : null
      }
    </div>
  );
};

export default SectionSliderNewAuthors;
