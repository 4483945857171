import React, { FC, useState } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Textarea from "components/Textarea/Textarea";
import Button from "components/Button/Button";
import { Rating } from 'react-simple-star-rating'
import { PostDataType } from "data/types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { saveComments } from "../../redux/actionCreators/appActionCreators";
export interface SingleCommentFormProps {
  className?: string;
  textareaRef?: React.MutableRefObject<null>;
  defaultValue?: string;
  rows?: number;
  appData?: PostDataType;
  onClickReset?: () => void;
  onClickSubmit?: () => void;
  handleRating?: (rate: number) => void;
  handleChange: (e: any) => void;
}

const SingleCommentForm: FC<SingleCommentFormProps> = ({
  className = "mt-5",
  textareaRef,
  defaultValue = "",
  rows = 4,
  appData,
  onClickReset,
  onClickSubmit,
  handleRating,
  handleChange
}) => {

  const { t, i18n } = useTranslation();
  const currentUser = useSelector((state: any) => state.auth)
  const dispatch = useDispatch();

  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value: number, index: number) => console.log(value, index)


  return (
    <>

      <div className="ratingBlock" >
        <Rating
          onClick={handleRating}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
          onPointerMove={onPointerMove}
        /* Available Props */
        />
      </div>
      <Textarea
        placeholder="Add to discussion"
        ref={textareaRef}
        required={true}
        value={defaultValue}
        defaultValue={defaultValue}
        onChange={(e) =>
          handleChange(e.currentTarget.value)
        }
        rows={rows}
      />
      <div className="mt-2 space-x-3">
        <ButtonPrimary onClick={onClickSubmit} type="submit">
          Submit
        </ButtonPrimary>
        <Button type="button" pattern="white" onClick={onClickReset}>
          Reset
        </Button>
      </div>
    </>

  );
};

export default SingleCommentForm;
