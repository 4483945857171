import common from './common';
// import onboarding from './onboarding';
// import navigate from './navigate';
import language from '../language';
import user from './user';
export default {
    common,
    user,
    // onboarding,
    // navigate,
    language,
};
