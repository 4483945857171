import React, { FC, useEffect, useRef, useState } from "react";
import Tag from "components/Tag/Tag";
import SingleAuthor from "./SingleAuthor";
import SingleCommentForm from "./SingleCommentForm";
import SingleCommentLists from "./SingleCommentLists";
import SingleContentDemo from "./SingleContentDemo";
import { DEMO_TAGS } from "data/taxonomies";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import PostCardLikeAction from "components/PostCardLikeAction/PostCardLikeAction";
import PostCardCommentBtn from "components/PostCardCommentBtn/PostCardCommentBtn";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { PostDataType } from "data/types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { saveComments } from "../../redux/actionCreators/appActionCreators";
import CommentCard, { CommentType } from "components/CommentCard/CommentCard";
import ButtonPrimary from "components/Button/ButtonPrimary";
import MySlider from "components/MySlider";

const demoTags = DEMO_TAGS.filter((_, i) => i < 9);

export interface SingleContentProps {
  appData?: PostDataType
}

const SingleContent: FC<SingleContentProps> = ({
  appData
}) => {
  const endedAnchorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLButtonElement>(null);
  //
  const currentUser = useSelector((state: any) => state.auth)
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);


  const { t, i18n } = useTranslation();
  const [commentLists, setCommentLists] = useState<CommentType[] | undefined>([]);

  const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);
  //

  const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
    threshold: 0,
    root: null,
    rootMargin: "0%",
    freezeOnceVisible: false,
  });

  useEffect(() => {
    const handleProgressIndicator = () => {
      const entryContent = contentRef.current;
      const progressBarContent = progressRef.current;

      if (!entryContent || !progressBarContent) {
        return;
      }

      const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
      let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      let scrolled = (winScroll / totalEntryH) * 100;

      progressBarContent.innerText = scrolled.toFixed(0) + "%";

      if (scrolled >= 100) {
        setIsShowScrollToTop(true);
      } else {
        setIsShowScrollToTop(false);
      }
    };

    const handleProgressIndicatorHeadeEvent = () => {
      window?.requestAnimationFrame(handleProgressIndicator);
    };
    handleProgressIndicator();
    window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
    if (appData) {
      setCommentLists(appData.comments);
    }
    return () => {
      window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
    };

  }, []);

  const showLikeAndCommentSticky =
    !endedAnchorEntry?.intersectionRatio &&
    (endedAnchorEntry?.boundingClientRect.top || 0) > 0;

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate)

    // other logic
  }
  const handleChange = (comment: string) => {
    setComment(comment)

    // other logic
  }

  const onClickSubmit = () => {
    console.log('click submit..');
    if (comment.length < 3) {
      toast.error(t("common:comment_required"));
      return;
    }
    if (rating < 1) {
      toast.error(t("common:rating_required"));
      return;
    }
    dispatch(saveComments(appData?.id, currentUser.user.user_uuid, comment, rating)).then((resp: any) => {

      toast.success(t("common:comment_submited"))
    })
  }
  const onClickReset = () => {

    console.log('click reset..');
    setComment("");
    setRating(0);
  }
  const renderCard = (item: any, index: number) => {
    return <div className="flex-shrink-0 relative w-full aspect-w-5 aspect-h-5 h-0 rounded-2xl overflow-hidden group">
      <img src={item.media_url} />
    </div>
  }


  console.log('....commentLists... single content.. ', appData?.comments);
  return (
    <div className="relative">
      <div className="nc-SingleContent space-y-10">


        {
          appData?.galleryImgs ? <>
            {

              <MySlider
                data={appData?.galleryImgs}
                renderItem={(item, indx) => renderCard(item, indx)}
                itemPerRow={4}
              />


            }
          </> : null
        }

        {/* ENTRY CONTENT */}
        <div
          id="single-entry-content"
          className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
          ref={contentRef}
        >
          <SingleContentDemo appData={appData} />
        </div>

        {/* TAGS */}
        <div className="max-w-screen-md mx-auto flex flex-wrap">
          {appData?.tags?.map((item) => (
            <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
          ))}
        </div>

        {/* AUTHOR */}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        <div className="max-w-screen-md mx-auto ">
          <SingleAuthor author={appData?.author} />
        </div>

        {/* COMMENT FORM */}
        <div
          id="comments"
          className="scroll-mt-20 max-w-screen-md mx-auto pt-5"
        >
          <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
            {t("common:responses")} ({appData?.comments?.length})
          </h3>
          <SingleCommentForm
            appData={appData}
            defaultValue={comment}

            onClickReset={onClickReset}
            onClickSubmit={onClickSubmit}
            handleRating={handleRating}
            handleChange={handleChange}
          />
        </div>

        {/* COMMENTS LIST */}
        <div className="max-w-screen-md mx-auto">
          {/* <SingleCommentLists commentLists={commentLists} /> */}
          <ul className="nc-SingleCommentLists space-y-5">

            {
              appData?.comments?.map((item: CommentType) =>
                <>
                  <CommentCard comment={item} />
                </>

              )
            }



            <ButtonPrimary className="dark:bg-primary-700 w-full">
              View full comments ( {appData?.comments?.length} comments)
            </ButtonPrimary>
          </ul>
          <div ref={endedAnchorRef}></div>
        </div>
      </div>
      <div
        className={`sticky mt-8 bottom-8 z-40 justify-center ${showLikeAndCommentSticky ? "flex" : "hidden"
          }`}
      >
        <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
          {/* <PostCardLikeAction className="px-3 h-9 text-xs" />
          <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
          <PostCardCommentBtn
            isATagOnSingle
            className={` flex px-3 h-9 text-xs`}
          />
          <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div> */}

          <button
            className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${isShowScrollToTop ? "flex" : "hidden"
              }`}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <ArrowUpIcon className="w-4 h-4" />
          </button>

          <button
            ref={progressRef}
            className={`w-9 h-9 items-center justify-center ${isShowScrollToTop ? "hidden" : "flex"
              }`}
            title="Go to top"
          >
            %
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleContent;
