
export default {

    username: "Username",
    login: "Sign in",
    forgot_password: "Forgot password",
    sign_up: "Sign up",
    email: "Email address",
    email_has_been_sent: "Email has been sent!",
    password: "Password",
    go_back_for: "Go back for",
    dash_board: "Dash board",
    submit_app: "Submit App",
    text_apps: "My Apps",
    edit_profile: "Edit profile",
    developer_note: "Register as a developer to publish your H5 app",
    terms_note: "Please read and agree our terms and conditions",
    must_agree_terms: "You must agree our terms and conditions"
}