import React, { FC, useEffect, useState } from "react";
import WidgetAuthors from "components/WidgetAuthors/WidgetAuthors";
import WidgetCategories from "components/WidgetCategories/WidgetCategories";
import WidgetPosts from "components/WidgetPosts/WidgetPosts";
import WidgetTags from "components/WidgetTags/WidgetTags";
import { DEMO_AUTHORS } from "data/authors";
import { DEMO_POSTS } from "data/posts";
import { DEMO_CATEGORIES, DEMO_TAGS } from "data/taxonomies";
import { PostAuthorType, PostDataType, TaxonomyType } from "data/types";
import { useDispatch } from "react-redux";
import { getApps, getDevelopers, getTrendingCategories } from "../../redux/actionCreators/appActionCreators";
import { PostAuthorTypePaginationResponse, PostAuthorTypeResponse, PostDataTypeResponse, PostDataWithPagingResponse, TaxonomyTypeResponse } from "models/appCategory";

export interface SidebarProps {
  className?: string;
}


const tags = DEMO_TAGS.filter((_, i) => i > 5);
// const categories = DEMO_CATEGORIES.filter((_, i) => i > 7 && i < 13);


export const Sidebar: FC<SidebarProps> = ({ className = "space-y-6 " }) => {

  const dispatch = useDispatch();
  const [authors, setAuthors] = useState<PostAuthorType[]>([]);
  const [hotestApps, setHotestApps] = useState<PostDataType[]>([]);
  const [categories, setCategories] = useState<TaxonomyType[]>([]);

  useEffect(() => {

    dispatch(getApps('hotest'))
      .then((apps: PostDataWithPagingResponse) => {
        console.log('..Apps..', apps.value)
        setHotestApps(apps.value.data);
      });
    dispatch(getDevelopers(0, 10))
      .then((apps: PostAuthorTypePaginationResponse) => {
        console.log('..developer..', apps.value);
        if (apps.value.data.length > 0) {
          setAuthors(apps.value.data);
        }

      });
    dispatch(getTrendingCategories())
      .then((resp: TaxonomyTypeResponse) => {
        console.log('..trending categories..', resp.value);
        setCategories(resp.value);
      });

  }, []);
  return (
    <div className={`nc-SingleSidebar ${className}`}>
      <WidgetTags tags={tags} />
      <WidgetCategories categories={categories} />
      <WidgetAuthors authors={authors} />
      <WidgetPosts posts={hotestApps} />
    </div>
  );
};
