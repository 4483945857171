import React, { FC } from "react";
import NcImage from "components/NcImage/NcImage";
import { PostDataType, TaxonomyType, TwMainColor } from "data/types";
import Badge from "components/Badge/Badge";
import Link from "components/Link";

export interface CardCategory2Props {
  className?: string;
  taxonomy: PostDataType;
  index?: string;
}

const CardMobileApp: FC<CardCategory2Props> = ({
  className = "",
  taxonomy,
  index,
}) => {
  const { title, appIconUrl, href } = taxonomy;
  return (
    <Link
      href={href}
      className={`nc-CardCategory2 relative flex flex-col items-center justify-center text-center px-3 py-5 sm:p-6 bg-white dark:bg-neutral-900 rounded-3xl transition-colors ${className}`}
    >

      <NcImage
        containerClassName={`relative flex-shrink-0 w-20 h-20 rounded-full shadow-lg overflow-hidden z-0`}
        src={appIconUrl || ""}
        fill
        sizes="80px"
        alt="categories"
        className="object-cover "
      />
      <div className="mt-3">
        <h2 className={`text-base font-semibold`}>{title}</h2>
        {/* <span
          className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
        >
          {count} Articles
        </span> */}
      </div>
    </Link>
  );
};

export default CardMobileApp;
