// import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';


import auth from './authReducer';
// import messaging from './messagingReducer';
import appReducer from './appReducer';

const rootReducer = combineReducers({
    // toastr: toastrReducer,
    auth,
    appReducer
    // admin,
    // messaging,
});

export default rootReducer;
