import React, { FC } from "react";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Image from "../Image";
import { useTranslation } from "react-i18next";
import Link from "components/Link";
import { useNavigate } from "react-router-dom";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
    >
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <span className="text-xs uppercase tracking-wider font-medium text-neutral-400">
          {t("common:super_change")}
        </span>
        <h2 className="font-semibold text-3xl sm:text-4xl mt-3">
          {t("common:become_an_developer_share_talent")}
        </h2>
        <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
          {t("common:become_an_developer_note")}
        </span>
        <ButtonPrimary onClick={() => navigate("/signup")} className="mt-8"> {t("common:become_an_developer")}  </ButtonPrimary>
      </div>
      <div className="flex-grow">
        <Image
          alt="hero"
          sizes="(max-width: 768px) 100vw, 50vw"
          src={rightImg}
        />
      </div>
    </div>
  );
};

export default SectionBecomeAnAuthor;
