import React, { useEffect, useState } from "react";
import NcImage from "components/NcImage/NcImage";
import Pagination from "components/Pagination/Pagination";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomLink, PostDataType } from "data/types";
import { getMySubmittedApp } from "../../../../redux/actionCreators/appActionCreators";
import { defaultPageLimit } from "constants/variables";
import { PostDataWithPagingResponse } from "models/appCategory";
import Link from "components/Link";
import twFocusClass from "utils/twFocusClass";



const DashboardPosts = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNav, setPageNav] = useState<CustomLink[]>([]);
  const [apps, setApps] = useState<PostDataType[]>([]);
  const currentUser = useSelector((state: any) => state.auth);
  console.log('..current user..', currentUser)
  useEffect(() => {
    dispatch(getMySubmittedApp(currentUser.user.user_uuid, page, defaultPageLimit))
      .then((apps: PostDataWithPagingResponse) => {
        console.log('..app OR games..', apps.value)
        setApps(apps.value.data);
        setCurrentPage(apps.value.currentPage);
        setTotalPage(apps.value.totalPages);
        if (apps.value.totalPages > 1) {
          const pageData: CustomLink[] = [];
          for (let page = 1; page < apps.value.totalPages; page++) {
            pageData.push({
              label: "" + page,
              href: "/",
            })
          }
          setPageNav(pageData);
        }
      });
  }, []);
  const renderItem = (pag: CustomLink, index: number) => {
    if (index === 0) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        href={pag.href}
      >
        {pag.label}
      </Link>
    );
  };
  return (
    <Layout>
      <LayoutDashboard>
        <div className="flex flex-col space-y-8">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
              <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
                {
                  apps && apps.length > 0 ?
                    <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
                      <thead className="bg-neutral-50 dark:bg-neutral-800">
                        <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                          <th scope="col" className="px-6 py-3">
                            Title
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Status
                          </th>


                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                        {apps.map((item) => (
                          <tr key={item.id}>
                            <td className="px-6 py-4">
                              <div className="flex items-center w-96 lg:w-auto max-w-md overflow-hidden">
                                <NcImage
                                  containerClassName="flex-shrink-0 h-12 w-12 rounded-lg relative z-0 overflow-hidden lg:h-14 lg:w-14"
                                  src={item.appIconUrl}
                                  fill
                                  sizes="80px"
                                  alt="post"
                                />
                                <div className="ml-4 flex-grow">
                                  <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                    {item.title}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {item.status === 'published' ? (
                                <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-teal-100 text-teal-900 lg:text-sm">
                                  Active
                                </span>
                              ) : (
                                <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                                  {item.status}
                                </span>
                              )}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-neutral-300">
                              <a
                                href="/#"
                                className="text-primary-800 dark:text-primary-500 hover:text-primary-900"
                              >
                                Edit
                              </a>
                              {` | `}
                              <a
                                href="/#"
                                className="text-rose-600 hover:text-rose-900"
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table> : <div>
                      {t("common:no_record")}
                    </div>
                }
              </div>
            </div>
          </div>

          {
            totalPage > 1 ?
              <nav
                className={`nc-Pagination inline-flex space-x-1 text-base font-medium  `}
              >
                {pageNav.map(renderItem)}
              </nav>
              : null
          }
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardPosts;
