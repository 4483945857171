import { SERVICE_URL, UCenter_URL } from '../../constants/variables';
import {
  AUTH_CHANGE_PASSWORD_ALT,
  AUTH_CHANGE_PASSWORD,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_PASSWORD_VERIFY,
  CREATE_USER
} from '../actionTypes';


export const loginUser = (identifier: string, password: string): any => {
  return (dispatch: any) => {
    return dispatch({
      type: AUTH_LOGIN,
      payload: fetch(`${UCenter_URL}/auth/local`, {
        body: JSON.stringify({
          identifier: identifier,
          password,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((responseData) => {
          console.log('..user login.. response data..' + JSON.stringify(responseData));
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};


export const logoutUser = (): any => {
  return {
    type: AUTH_LOGOUT,
    payload: new Promise((resolve) => resolve(true)),
  };
};

export const resetPassword = (email: string): any => {
  return (dispatch: any) => {
    return dispatch({
      type: AUTH_RESET_PASSWORD,
      payload: fetch(`${UCenter_URL}/auth/forgot-password`, {
        body: JSON.stringify({
          email,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((responseData) => {
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};

export const createUser = (
  username: string,
  email: string,
  password: string,
  is_developer: boolean): any => {
  return {
    type: CREATE_USER,
    payload: fetch(`${UCenter_URL}/auth/local/register`, {
      body: JSON.stringify({
        "email": email,
        "username": username,
        "password": password,
        "isVolunteer": false,
        "phoneNumber": email.split('@')[0],
        "is_developer": is_developer,
        "from_app": "H5-App-Store"
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then((r) => r.json())
      .then((responseData) => {
        console.log('...create user .. API call', responseData);
        if (responseData.statusCode >= 300) {
          return Promise.reject(responseData);
        } else {
          return responseData;
        }
      }),
  };
};

/*
export const changePassword = (password: string) => {
  return (dispatch, getState) => {
    let token = getState().auth.jwt;
    console.log('password: =' + password + ' token=' + token);
    return dispatch({
      type: AUTH_CHANGE_PASSWORD,
      payload: fetch(`${SERVICE_URL}/residents/change-password`, {
        body: JSON.stringify({
          password,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((responseData) => {
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};





export const verifyResetPassword = (mobileNumber, mobileVerificationCode) => {
  return (dispatch) => {
    return dispatch({
      type: AUTH_RESET_PASSWORD_VERIFY,
      payload: fetch(`${SERVICE_URL}/public/verify-reset-password`, {
        body: JSON.stringify({
          mobileNumber,
          mobileVerificationCode,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((r) => r.json())
        .then((responseData) => {
          if (responseData.statusCode >= 300) {
            return Promise.reject(responseData);
          } else {
            return responseData;
          }
        }),
    });
  };
};


*/