import React, { DOMElement, useEffect, useRef, useState } from "react";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Select from "components/Select/Select";
import Textarea from "components/Textarea/Textarea";
import Label from "components/Label/Label";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import { localStorageKV } from "utils/localStorage";
import { AppCategory, AppData, AppResponse } from "models/appCategory";
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import { appSchema } from "schema/appSchema";
import { FormFeedback, Spinner } from "reactstrap";
import { RadioGroup } from "app/headlessui";
import twFocusClass from "utils/twFocusClass";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { UPLOAD_URL, availableLanguage, } from "constants/variables";
import MySlider from "components/MySlider";
import { Bounce, toast } from 'react-toastify';
import { saveAppData } from "../../../../redux/actionCreators/appActionCreators";
import { useNavigate } from "react-router-dom";
// import tinymce from "tinymce";
// import Editor from 'react-simple-wysiwyg/src/index';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from 'ckeditor5';
// import 'ckeditor5/ckeditor5.css';

// import {
//   BtnBold,
//   BtnItalic,
//   Editor,
//   EditorProvider,
//   Toolbar
// } from 'react-simple-wysiwyg/src/editor';

const renderCheckIcon = () => {
  return (
    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DashboardSubmitPost = () => {
  const currentUser = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const fullDescRef = useRef(null);
  const [categories, setCategories] = useState<AppCategory[]>([]);
  useEffect(() => {
    const categoriesData = localStorageKV.getItem("app_categories");


    if (categoriesData) {

      setCategories(JSON.parse(categoriesData));
    }
    // tinymce.init({
    //   selector: 'textarea#fullDescription'
    // });
  }, []);
  // const [fullDescription, setFullDescription] = useState(' ');

  const maxAppNameLength = 40;
  const maxShortDescLength = 200;

  const formik = useFormik({
    initialValues: {
      appName: "",
      appOrgame: "",
      shortDescription: "",
      fullDescription: "",
      videoUrl: "",
      websiteUrl: "",
      tags: "",
      languagesCodeId: 41,
      categoriesId: 0,
      file_app_logo: null,
      file_featured_images: null,
      files_screenshots: []
    },
    isInitialValid: false,
    // validationSchema: appSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      console.log(" start to submit", values);
      // do validate
      if (!values.file_app_logo) {
        toast.error(t("common:please_upload_a_app_logo"));
        setSubmitting(false);
        return;
      }
      if (!values.file_featured_images) {
        toast.error(t("common:please_upload_a_featured_image"));
        setSubmitting(false);
        return;
      }
      if (!values.files_screenshots || values.files_screenshots.length < 3 || values.files_screenshots.length > 8) {
        toast.error(t("common:please_upload_screen_shots"));
        setSubmitting(false);
        return;
      }
      const appData: AppData = {
        id: 0,
        userUuid: currentUser.user.user_uuid,
        appName: values.appName,
        languagesCodeId: values.languagesCodeId,
        appOrgame: Number.parseInt(appTypeSelected.id),
        shortDescription: values.shortDescription,
        fullDescription: values.fullDescription ?? '',
        appIconUrl: "",
        videoUrl: values.videoUrl,
        categoriesId: values.categoriesId,
        websiteUrl: values.websiteUrl,
        featureGraphicUrl: "",
        tags: values.tags,
        screenShots: []
      }
      uploadAppLogo(values, appData);
      // uploadScreenShots(values, appData);

    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    values,
    setValues,
    isValid,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = formik;
  const appTypes = [

    { name: "Game", id: "1", label: "Game" },
    { name: "App", id: "2", label: "App" },
  ];
  const [appTypeSelected, setAppTypeSelected] = useState(appTypes[0]);
  const radioActive = "bg-primary-6000 text-white dark:bg-primary-700";
  const radionDeActive = "bg-white border-t border-neutral-50";
  const [appTypeClass1, setAppTypeClass1] = useState(radioActive);
  const [appTypeClass2, setAppTypeClass2] = useState(radionDeActive);
  const onAppTypeChange = (value: any) => {
    console.log('...App type. change.', value);
    setAppTypeSelected(value);
    if (value.id === "1") {
      setAppTypeClass1(radioActive);
      setAppTypeClass2(radionDeActive);
    } else {
      setAppTypeClass2(radioActive);
      setAppTypeClass1(radionDeActive);
    }
  }
  const renderCard = (item: any, index: number) => {
    return <div className="flex-shrink-0 relative w-full aspect-w-5 aspect-h-5 h-0 rounded-2xl overflow-hidden group">
      <img src={URL.createObjectURL(item)} />
    </div>
  }
  const uploadAppLogo = (values: any, appData: AppData) => {

    //do upload
    const data = new FormData();
    const appLogo = values.file_app_logo;

    data.append('photo', appLogo, appLogo.name);

    fetch(UPLOAD_URL + "/upload", {
      method: 'POST',
      body: data,
    }).then((response) => response.json())
      .then((response) => {
        console.log('response', response);
        // setUploadFile(response.path);
        appData.appIconUrl = response.path;
        uploadFeaturedImages(values, appData);
        // updateProfileAction(response.path)
      })
      .catch((error) => {
        console.log('error', error);
        toast.error(t("common:upload_file_error"))
      });

  }
  const uploadFeaturedImages = (values: any, appData: AppData) => {

    //do upload
    const data = new FormData();
    const featuredImage = values.file_featured_images;

    data.append('photo', featuredImage, featuredImage.name);

    fetch(UPLOAD_URL + "/upload", {
      method: 'POST',
      body: data,
    }).then((response) => response.json())
      .then((response) => {
        console.log('response', response);
        // setUploadFile(response.path);
        appData.featureGraphicUrl = response.path;
        uploadScreenShots(values, appData);
        // updateProfileAction(response.path)
      })
      .catch((error) => {
        console.log('error', error);
      });

  }
  const uploadScreenShots = (values: any, appData: AppData) => {

    //do upload
    const data = new FormData();
    const screenShots = values.files_screenshots;

    screenShots.map((item: File) => {
      data.append('files', item, item.name);
    })


    fetch(UPLOAD_URL + "/upload-multiple-files", {
      method: 'POST',
      body: data,
    }).then((response) => response.json())
      .then((response) => {
        console.log('response', response);
        // setUploadFile(response.path);
        // appData.appIconUrl = response.path;
        const rootPath = response.rootPath;
        const data: [] = response.data;
        const screenShots: string[] = [];
        data.map(item => {
          screenShots.push(rootPath + item["filename"])
        });

        console.log('..screen shots..', screenShots);
        appData.screenShots = screenShots;
        submitAppData(appData);
        // updateProfileAction(response.path)
      })
      .catch((error) => {
        console.log('error', error);
      });

  }
  const submitAppData = (appData: AppData) => {
    dispatch(saveAppData(appData)).then((resp: AppResponse) => {
      console.log(" save app data..", resp.value);
      toast.success(t("common:app_submited"));
      navigate("/dashboard/apps")
    })
  }
  // function onChange(e: any) {
  //   setFullDescription(e.target.value);
  // }
  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit} >
            <label className="block md:col-span-2">
              <Label> {t("common:app_type")} *</Label>

              <RadioGroup value={appTypeSelected} onChange={onAppTypeChange}>
                <RadioGroup.Label className="sr-only">Type</RadioGroup.Label>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
                  {appTypes.map((type) => (
                    <RadioGroup.Option
                      key={type.name}
                      value={type}
                      className={({ active, checked }) => {
                        return (
                          `${type.id === "2" ? appTypeClass2 : appTypeClass1} relative shadow-lg rounded-lg px-3 py-3 cursor-pointer flex sm:px-5 sm:py-4 focus:outline-none `

                        );
                      }}
                    >
                      {({ active, checked }) => (
                        <div className="flex items-center justify-between w-full">
                          <div className="flex items-center">
                            <div className="text-sm">
                              <RadioGroup.Label
                                as="p"
                                className={`font-medium line-clamp-1 ${checked ? "text-white" : "text-neutral-900"
                                  }`}
                              >
                                {type.label}
                              </RadioGroup.Label>
                            </div>
                          </div>
                          {checked && (
                            <div className="flex-shrink-0 text-white">
                              {renderCheckIcon()}
                            </div>
                          )}
                        </div>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </label>
            <label className="block md:col-span-2">
              <Label> {t("common:app_title")} *</Label>
              <div>
                <Input type="text" className="mt-1"
                  id="appName"
                  name="appName"
                  value={values.appName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={40}
                  invalid={
                    touched.appName &&
                    errors.appName
                  }

                />
                <p style={{ textAlign: "end", paddingTop: "10px" }}>
                  {values.appName.length} / {maxAppNameLength}</p>
              </div>
              <FormFeedback>
                {errors.appName}
              </FormFeedback>
            </label>
            <label className="block md:col-span-2">
              <Label> {t("common:app_web_url")} *</Label>

              <Input type="text" className="mt-1"
                id="websiteUrl"
                name="websiteUrl"
                value={values.websiteUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={
                  touched.websiteUrl &&
                  errors.websiteUrl
                }
              />
              <p className="mt-1 text-sm text-neutral-500">
                {t("common:app_web_url_note")}
              </p>
              <FormFeedback>
                {errors.websiteUrl}
              </FormFeedback>
            </label>
            <label className="block md:col-span-2">
              <Label> {t("common:brief_description")} * </Label>

              <Textarea className="mt-1" rows={4}
                id="shortDescription"
                name="shortDescription"
                value={values.shortDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={200}
              />
              <p style={{ textAlign: "end", paddingTop: "10px" }}>
                {values.shortDescription.length} / {maxShortDescLength}</p>

              <p className="mt-1 text-sm text-neutral-500">
                {t("common:brief_description_note")}
              </p>
            </label>
            <label className="block">
              <Label> {t("common:category")} * </Label>

              <Select className="mt-1"
                id="categoriesId"
                name="categoriesId"

                value={values.categoriesId}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="-1"> {t("common:select")} </option>
                {
                  categories.map(item => <>
                    <option value={item.id} > {item.categories_name}</option>
                  </>)
                }

              </Select>
            </label>
            <label className="block">
              <Label> {t("common:primary_language")} * </Label>

              <Select className="mt-1"
                id="languagesCodeId"
                name="languagesCodeId"
                value={values.languagesCodeId}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="-1"> {t("common:select")} </option>
                {
                  availableLanguage.map(item => <>
                    <option value={item.dbId} > {item.name}</option>
                  </>)
                }

              </Select>
            </label>

            <label className="block md:col-span-2">
              <Label> {t("common:video_url")} </Label>

              <Input type="text" className="mt-1"
                id="videoUrl"
                name="videoUrl"
                value={values.videoUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={
                  touched.videoUrl &&
                  errors.videoUrl
                }
              />
              <FormFeedback>
                {errors.videoUrl}
              </FormFeedback>
            </label>

            <label className="block md:col-span-2">
              <Label> {t("common:tags")} </Label>

              <Input type="text" className="mt-1"
                id="tags"
                name="tags"
                value={values.tags}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={
                  touched.tags &&
                  errors.tags
                }
              />
              <p className="mt-1 text-sm text-neutral-500">
                {t("common:tags_note")}
              </p>
              <FormFeedback>
                {errors.tags}
              </FormFeedback>
            </label>
            <div className="block md:col-span-2">
              <Label> {t("common:app_logo")} *  </Label>

              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  {
                    values.file_app_logo ? <>
                      <img src={URL.createObjectURL(values.file_app_logo)} height={50} />
                    </> :

                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                  }
                  <div className="flex flex-col sm:flex-row text-sm text-neutral-6000">
                    <label
                      htmlFor="file_app_logo"
                      className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span> {t("common:upload_a_file")}  </span>
                      <input
                        id="file_app_logo"
                        name="file_app_logo"
                        type="file"
                        className="sr-only"
                        accept="image/png"
                        onChange={(e) => {
                          // Object is possibly null error w/o check

                          if (e.currentTarget.files) {
                            console.log('...e.currentTarget.files[0]..', e.currentTarget.files[0]);
                            setFieldValue("file_app_logo", e.currentTarget.files[0]);
                          }
                        }}
                      />
                    </label>
                    <p className="pl-1"> {t("common:drag_and_drop")}   </p>
                  </div>
                  <p className="text-xs text-neutral-500">
                    {t("common:upload_logo_image_note")}
                  </p>
                </div>
              </div>
            </div>

            <div className="block md:col-span-2">
              <Label> {t("common:featured_image")} *  </Label>

              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  {
                    values.file_featured_images ? <>
                      <img src={URL.createObjectURL(values.file_featured_images)} height={50} />
                    </> :
                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                  }
                  <div className="flex flex-col sm:flex-row text-sm text-neutral-6000">
                    <label
                      htmlFor="file_featured_images"
                      className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span> {t("common:upload_a_file")}  </span>
                      <input
                        id="file_featured_images"
                        name="file_featured_images"
                        type="file"
                        className="sr-only"
                        accept="image/png, image/jpeg"
                        onChange={(e) => {
                          // Object is possibly null error w/o check
                          if (e.currentTarget.files) {
                            setFieldValue("file_featured_images", e.currentTarget.files[0]);
                          }
                        }}
                      />
                    </label>
                    <p className="pl-1"> {t("common:drag_and_drop")}   </p>
                  </div>
                  <p className="text-xs text-neutral-500">
                    {t("common:upload_featured_image_note")}
                  </p>
                </div>
              </div>
            </div>

            <div className="block md:col-span-2">
              <Label> {t("common:phone_screenshots")}  *  </Label>

              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  {
                    values.files_screenshots ? <>
                      {

                        <MySlider
                          data={values.files_screenshots}
                          renderItem={(item, indx) => renderCard(item, indx)}
                          itemPerRow={4}
                        />


                      }
                    </> :
                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                  }
                  <div className="flex flex-col sm:flex-row text-sm text-neutral-6000">
                    <label
                      htmlFor="files_screenshots"
                      className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span> {t("common:upload_files")}  </span>
                      <input
                        id="files_screenshots"
                        name="files_screenshots"
                        type="file"
                        accept="image/png, image/jpeg"
                        className="sr-only"
                        multiple
                        onChange={(e) => {
                          // Object is possibly null error w/o check
                          if (e.currentTarget.files) {
                            console.log('.. e.currentTarget.files..', e.currentTarget.files);
                            const chosenFiles = Array.prototype.slice.call(e.currentTarget.files);
                            setFieldValue("files_screenshots", chosenFiles);
                          }
                        }}
                      />
                    </label>
                    <p className="pl-1"> {t("common:drag_and_drop")}   </p>
                  </div>
                  <p className="text-xs text-neutral-500">
                    {t("common:phone_screenshots_note")}
                  </p>
                </div>
              </div>
            </div>

            <label className="block md:col-span-2">
              <Label>  {t("common:app_full_description")} * </Label>
              {/* <EditorProvider>
                <Editor value={fullDescription ?? ''}
                  onClick={(e) => {

                  }}
                  onChange={(e) => {
                    setFullDescription(e.target.value)
                  }}
                >
                  <Toolbar>
                    <BtnBold />
                    <BtnItalic />
                  </Toolbar>
                </Editor>
              </EditorProvider> */}

              {/* <Editor style={{ minHeight: "300px" }}

                id="fullDescEditor"
                // onClick={(e) => {
                //   console.log('...item click...@@@@@@');
                //   const electments = document.getElementsByClassName("rsw-btn");

                //   if (electments) {
                //     console.log('..electment should focus');
                //     for (var i = 0; i < electments.length; i++) {
                //       const title = electments[i].getAttribute('title')
                //       if (title == 'Redo') {
                //         console.log('..title..', title);
                //         const btnRetry: Element = electments[i];

                //       }

                //     }

                //   } else {
                //     console.log('..electment not found ???')
                //   }

                // }}
                value={fullDescription ?? ' '} onChange={(e) => {
                  setFullDescription(e.target.value)
                }} /> */}


              <Textarea className="mt-1" rows={16}
                id="fullDescription"
                name="fullDescription"
                value={values.fullDescription}
                onChange={handleChange}
                onBlur={handleBlur}

              />
              <FormFeedback>
                {errors.fullDescription}
              </FormFeedback>
            </label>

            <ButtonPrimary className="md:col-span-2" type="submit">
              {isSubmitting ? <Spinner size={'sm'} /> : t("common:submit_app")}
            </ButtonPrimary>
          </form>
        </div>
      </LayoutDashboard>
    </Layout >
  );
};

export default DashboardSubmitPost;
