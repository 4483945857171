import SingleHeader4 from "app/(singles)/SingleHeader4";
import React, { useEffect, useState } from "react";
import Layout from "../layout";
import { PostDataType } from "data/types";
import { AppDataTypeResponse } from "models/appCategory";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAppByName } from "../../../../redux/actionCreators/appActionCreators";

const PageSingleTemplate4 = () => {
  const location = useLocation();
  console.log('..location..', location);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const appName = decodeURI(location.pathname.replace('/app/', ''));
  const [app, setApp] = useState<PostDataType>();
  const currentUser = useSelector((state: any) => state.auth);
  useEffect(() => {
    dispatch(getAppByName(appName, currentUser && currentUser.user ? currentUser.user.user_uuid : ''))
      .then((apps: AppDataTypeResponse) => {
        console.log('..get Apps by name..', apps.value)
        setApp(apps.value);
      });

  }, []);
  return (
    <Layout appData={app}>
      <div className="absolute top-0 inset-x-0 bg-neutral-900 dark:bg-black/30 h-[480px] md:h-[600px] lg:h-[700px] xl:h-[95vh]"></div>

      <header className="container pt-10 lg:pt-16 rounded-xl relative z-10">
        <SingleHeader4 app={app} />
      </header>
    </Layout>
  );
};

export default PageSingleTemplate4;
