import React, { useRef, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Image from "components/Image";
import Layout from "../layout";
import { useTranslation, Trans } from 'react-i18next';
import { createUser } from "../../../redux/actionCreators/authActionCreators";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Checkbox from "components/Checkbox/Checkbox";
import { Bounce, toast } from 'react-toastify';
const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputEmail, setInputEmail] = useState("");

  const [isDeveloperChecked, setIsDeveloperChecked] = useState(false)
  const cbDeveloperHandler = () => {
    setIsDeveloperChecked(!isDeveloperChecked)
  }

  const [isTermsChecked, setIsTermsChecked] = useState(false)
  const cbTermsHandler = () => {
    setIsTermsChecked(!isTermsChecked)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!isTermsChecked) {
      toast.error(t("user:must_agree_terms"));
      return;
    }




    dispatch(createUser(inputUsername, inputEmail, inputPassword, isDeveloperChecked))
      .then((response: any) => {
        console.log('..user login.. response:' + JSON.stringify(response));
        if (response.value.jwt) {
          console.log('mobile is verified......');
          navigate("/");
          // navigation.navigate('BottomTab', { mobileNumber: identifier });
        } else {
          console.log('mobile is un-verified....!!!!!');
          //
          // toastr.error('Username or password error!',);
        }

      })
      .catch((e: any) => {
        console.log('...Error...login error!');

      });


  };
  const cbDeveloperRef = useRef(null)
  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
        <Heading2> {t("user:sign_up")} </Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          {t("common:welcome_to_our_community")}
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/* <div className="grid gap-3">
          {loginSocials.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
            >
              <Image
                className="flex-shrink-0"
                src={item.icon}
                alt={item.name}
              />
              <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                {item.name}
              </h3>
            </a>
          ))}
        </div> */}
        {/* OR */}
        {/* <div className="relative text-center">
          <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
            OR
          </span>
          <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
        </div> */}
        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" action="#" onSubmit={handleSubmit} >
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              {t("user:username")}
            </span>
            <Input
              type="text"
              placeholder="username"
              value={inputUsername}
              onChange={(e: any) => setInputUsername(e.target.value)}
              className="mt-1"
              required
            />
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              {t("user:email")}
            </span>
            <Input
              type="email"
              placeholder="example@example.com"
              value={inputEmail}
              onChange={(e: any) => setInputEmail(e.target.value)}
              className="mt-1"
            />
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              {t("user:password")}
            </span>
            <Input type="password" className="mt-1"
              value={inputPassword}
              placeholder={t("user:password")}
              onChange={(e: any) => setInputPassword(e.target.value)}
              required
            />
          </label>
          <label className="block mt-1">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              {t("user:developer_note")}
            </span>
            <Checkbox name={"developer"} label="I am a Developer"
              isChecked={isDeveloperChecked}
              checkHandler={cbDeveloperHandler}


            />
          </label>
          <label className="block mt-1">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              {t("user:terms_note")}
            </span>
            <Checkbox name={"terms"} label="I had read and agree the terms and conditions"
              isChecked={isTermsChecked}
              checkHandler={cbTermsHandler}


            />
          </label>
          <ButtonPrimary type="submit">{t("common:continue")}</ButtonPrimary>
        </form>

        {/* ==== */}
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Already have an account? {` `}
          <NcLink href="/login">Sign in</NcLink>
        </span>
      </div>
    </Layout>
  );
};

export default PageSignUp;
