import React, { useEffect, useState } from "react";
import ModalCategories from "../ModalCategories";
import ModalTags from "../ModalTags";
import { DEMO_POSTS } from "data/posts";
import { CustomLink, PostDataType, TaxonomyType } from "data/types";
import { DEMO_CATEGORIES, DEMO_TAGS } from "data/taxonomies";
import { DEMO_AUTHORS } from "data/authors";
import Pagination from "components/Pagination/Pagination";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import ButtonSecondary from "components/Button/ButtonSecondary";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import Image from "components/Image";
import { AppCategory, PostDataTypePaginationResponse, PostDataTypeResponse, TaxonomyTypeResponse } from "models/appCategory";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAppsByCategoryName, getAppCategories, getTags } from "../../../redux/actionCreators/appActionCreators";
import { availableLanguage, defaultPageLimit } from "../../../constants/variables";
import Link from "components/Link";
import { localStorageKV } from "utils/localStorage";
// Tag and category have same data type - we will use one demo data
// const posts: PostDataType[] = DEMO_POSTS.filter((_, i) => i < 16);

const PageArchive = () => {

  const location = useLocation();
  console.log('..location..', location);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [apps, setApps] = useState<PostDataType[]>([]);
  const categoryName = decodeURI( location.pathname.replace('/apps/', ''));
  const [ totalApps, setTotalApps ] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [ pageData , setPageData ] = useState([]);
  const [tagsData, setTagsData] = useState<TaxonomyType[]>([]);
  const FILTERS = [
    { name: t("common:most_recent") },
    // { name: "Curated by Admin" },
    // { name: "Most Appreciated" },
    { name: t("common:most_discussed")  },
    { name: t("common:most_viewed")  },
  ];
  const renderPageItem = (pag: CustomLink, index: number) => {
    if (index === 0) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white `}
        >
          {pag.label}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        key={index}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 `}
        href={pag.href}
      >
        {pag.label}
      </Link>
    );
  };
  const currentLanguageCode = i18n.language;
  const [categories, setCategories] = useState<TaxonomyType[]>([]);
  useEffect(() => {
    // dispatch(getAppsByCategoryName(categoryName,page, defaultPageLimit))
    //   .then((apps: PostDataTypePaginationResponse) => {
    //     console.log('..games..', apps.value)
    //     setApps(apps.value.data);
    //     setTotalApps(apps.value.totalResults)
    //   })
    dispatch(getTags(0, 25))
      .then((apps: TaxonomyTypeResponse) => {
        console.log('..get tags..', apps.value)
        setTagsData(apps.value);
      });

    dispatch(getAppsByCategoryName(categoryName,page, defaultPageLimit))
    .then((apps: PostDataTypeResponse) => {
      console.log('..games..', apps.value)
      setApps(apps.value);
    });


    const categoriesData = localStorageKV.getItem("app_categories");
    console.log('...categoriesData..',categoriesData);
    const language = availableLanguage.find(item => item.languagesCode === currentLanguageCode);
    let languageCode = "en";
    if (language) {
      languageCode = language.languagesCode;
    }
    if (categoriesData) {
      const appCategories: TaxonomyType[] = [];
      const data = JSON.parse(categoriesData);
      data.map((item: AppCategory) => {
        if (item.parent_id > 0 && item.languages_code === languageCode) {
          const category: TaxonomyType = {
            id: item.id,
            name: item.categories_name,
            href: "/apps/" + item.categories_name,
            taxonomy: "category"
          }
          appCategories.push(category)
        }

      })
      setCategories(appCategories);
    } else {


      dispatch(getAppCategories())
        .then((categories: any) => {
          // console.log(' categories 555555...' + JSON.stringify(categories));
          const data: AppCategory[] = categories.value;

          const appCategories: TaxonomyType[] = [];
          data.map((item: AppCategory) => {
            if (item.parent_id > 0 && item.languages_code === languageCode) {
              const category: TaxonomyType = {
                id: item.id,
                name: item.categories_name,
                href: "/apps/" + item.categories_name,
                taxonomy: "category"
              }
              appCategories.push(category)
            }

          })
          localStorageKV.saveItem("app_categories", JSON.stringify(appCategories));
          setCategories(appCategories);
        })
        .catch((error: any) => {
          // setAlertData(null);
          console.log('...get categories 00000..' + JSON.stringify(error));
        });
    }
  }, []);
  return (
    <div className={`nc-PageArchive`}>
      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="relative aspect-w-16 aspect-h-13 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-5 rounded-3xl md:rounded-[40px] overflow-hidden z-0">
          <Image
            alt="archive"
            fill
            src="https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            className="object-cover w-full h-full rounded-3xl md:rounded-[40px]"
            sizes="(max-width: 1280px) 100vw, 1536px"
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
              {categoryName}
            </h2>
            <span className="block mt-4 text-neutral-300">{totalApps} {t("common:apps")}</span>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container pt-10 pb-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <div>
          <div className="flex flex-col sm:justify-between sm:flex-row">
            <div className="flex space-x-2.5">
              <ModalCategories categories={categories} />
              <ModalTags tags={tagsData} />
            </div>
            <div className="block my-4 border-b w-full border-neutral-300 dark:border-neutral-500 sm:hidden"></div>
            <div className="flex justify-end">
              <ArchiveFilterListBox lists={FILTERS} />
            </div>
          </div>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {apps.map((post) => (
              <Card11 key={post.id} post={post} />
            ))}
          </div>

          {/* PAGINATIONS */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {/* <Pagination /> */}
            <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium `}
    > 
      {
      pageData.length > 0 ?  pageData.map(renderPageItem): ''
      // DEMO_PAGINATION.map(renderPageItem)
      }
    </nav>
            {/* <ButtonPrimary>Show me more</ButtonPrimary> */}
          </div>
        </div>

        {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionGridCategoryBox
            categories={DEMO_CATEGORIES.filter((_, i) => i < 10)}
          />
          <div className="text-center mx-auto mt-10 md:mt-16">
            <ButtonSecondary loading>Show me more</ButtonSecondary>
          </div>
        </div> */}

        {/* === SECTION 5 === */}
        <SectionSliderNewAuthors
          heading= { t("common:top_elite_developer") } 
          subHeading= { t("common:discover_our_elite_developer") } 
          authors={DEMO_AUTHORS.filter((_, i) => i < 10)}
        />

        {/* SUBCRIBES */}
        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageArchive;
