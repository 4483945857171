import { Bounce } from "react-toastify";

//local
// export const SERVICE_URL = 'http://192.168.0.197:4340/v1';
// export const UPLOAD_URL = 'http://192.168.0.197:4338'
// export const UCenter_URL = 'http://192.168.0.197:4337/api'
export const WEB_SOCKET_SERVER = 'http://192.168.0.197:4567'
// live
export const SERVICE_URL = 'https://h5app.world/api/v1';
export const UCenter_URL = 'https://rest.crutch.world/api'
export const UPLOAD_URL = 'https://quiz-api.crutch.world';

export const CONTACT_US_URL = 'https://crutch.world/contact-us/'

export const appVersionCode = "1.1.0";
export const cacheFileName = 'local_cache.json';
export const FETCH_RESULTS = 10;
export const MAX_LENGTH = 50;
export const termsUrl = 'https://crutch.world/privacy'
export const defaultPageLimit = 20;
export const availableLanguage = [
    {
        id: "English",
        name: "English",
        languagesCode: "en",
        description: "United State",
        href: "##",
        active: true,
        dbId: 41
    },
];
