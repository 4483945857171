import React, { useState } from "react";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { resetPassword } from "../../../redux/actionCreators/authActionCreators";

const PageForgotPass = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [inputEmail, setInputEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    dispatch(resetPassword(inputEmail)).then((resp: any) => {
      setIsEmailSent(true)
    })
  }
  return (
    <Layout>

      {isEmailSent ?
        <>
          <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
            <Heading2> {t("user:email_has_been_sent")} </Heading2>
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">

            </span>
          </header>

          <div className="max-w-md mx-auto space-y-6">

          </div>

        </> :
        <>
          <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
            <Heading2> {t("user:forgot_password")} </Heading2>
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
              {t("common:welcome_to_our_community")}
            </span>
          </header>

          <div className="max-w-md mx-auto space-y-6">
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6" action="#" onSubmit={handleSubmit}>
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("user:email")}
                </span>
                <Input
                  type="email"
                  placeholder="example@example.com"
                  value={inputEmail}
                  onChange={(e: any) => setInputEmail(e.target.value)}
                  className="mt-1"
                  required
                />
              </label>
              <ButtonPrimary type="submit">{t("common:continue")}</ButtonPrimary>
            </form>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              {t("user:go_back_for")} {` `}
              <NcLink href="/login"> {t("user:login")}  </NcLink>
              {` / `}
              <NcLink href="/signup"> {t("user:sign_up")}  </NcLink>
            </span>
          </div>

        </>
      }
    </Layout>
  );
};

export default PageForgotPass;
