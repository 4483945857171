import common from './common';
// import onboarding from './onboarding';
// import navigate from './navigate';
import language from '../language';

export default {
    common,
    // onboarding,
    // navigate,
    language,
};
