export default {
    sign_in: "Sign In",
    search: "Search",
    home: "Home",
    favorites: "Favorites",
    saved: "Saved",
    settings: "Settings",
    welcome_to_our_community: "Welcome to our blog magazine Community",
    continue: "Continue",
    text_app_categories: "App Categories",
    app_title: "App title",
    brief_description: "Brief Description",
    brief_description_note: "Brief description for your App",
    category: "Category",
    select: "– select –",
    tags: "Tags",
    featured_image: "Featured Image",
    upload_a_file: "Upload a file",
    drag_and_drop: "or drag and drop",
    app_type: "Type",
    app_logo: "App icon",
    upload_logo_image_note: "Your app icon must be a PNG,  1024 px by 1024 px ",
    upload_featured_image_note: "Your feature graphic must be a PNG or JPEG, up to 15 MB, and 1,024 px by 500 px",
    phone_screenshots: "Phone screenshots",
    phone_screenshots_note: "Upload 3-8 phone screenshots. Must be PNG or JPEG, with each side between 320 px and 3,840 px",
    upload_files: "Upload files",
    app_web_url: "App Url",
    app_full_description: "Full description",
    submit_app: "Submit App",
    app_web_url_note: "Your H5 App Website Url",
    primary_language: "Primary Language",
    please_upload_a_app_logo: "Please upload App Logo",
    please_upload_a_featured_image: "Please upload feature image",
    please_upload_screen_shots: "Please upload 3-8 phone screenshots",
    video_url: "Video Url",
    upload_file_error: "Sorry, file upload failed, please try again later",
    app_submited: "You're App has been submited!",
    tags_note: "Please enter your tags, use , to split it, for example: Software libraries, technical demos",
    my_account: "My Account",
    my_apps: "My Apps",
    my_installed_apps: "My Installed Apps",
    dark_theme: "Dark theme",
    explore_hotest_game: "Explore hotest game",
    explore_latest_game: "Explore latest game",
    explore_most_outstanding_game: "Discover the most outstanding game in all topics of life.",
    super_change: "supper change your planning powers",
    become_an_developer_share_talent: "Become an developer and share your great talent",
    become_an_developer_note: "Become an developer you can earn extra income by your H5 App. Play and share new App on just about any topic. Everyone’s welcome.",
    become_an_developer: " Become an developer",
    no_record: "No record match",
    copy_right: "2022 - 2024. All Rights Reserved - Crutch World ",
    comment_required: "Please enter your comments",
    rating_required: "Please give your rating",
    comment_submited: "Your comment has been submit.",
    newest_developer: "Newest Developers",
    discover_developer: "🎭 Discover Developers",
    view_all: "View all",
    trending_categories: "✨ Trending Categories",
    popular_apps: "🎯 Popular Apps",
    apps: "Apps",
    responses: "Responses",
    develop_by: "DEVELOP BY",
    read_more: "Read more",
    top_elite_developer: "Top elite developers",
    discover_our_elite_developer: "Discover our elite developers",
    discover_other_categories: "Discover other categories",
    other: "Other",
    categories: "Categories",
    most_recent: "Most Recent",
    most_discussed: "Most Discussed",
    most_viewed: "Most Viewed",
    tags: "Tags",
    discover_other_tags: "Discover other tags",
    install: "Install",
    copy_link: "Copy link",
    comment_this_app: "Comment this app",
    report_this_app: "Report this app",
    app_installed: "App Installed!",
    my_installed_apps_desc: "Play the App Or uninstall it",
    no_record: "No Record",
    app_title: "Title",
    uninstallApp: "Uninstall",
    appInstalled: "Installed"

}