class LocalStorageKV {
    getItem = (itemKey: string) => {
        return localStorage.getItem(itemKey);
    }
    saveItem = (itemKey: string, itemValue: string) => {
        localStorage.setItem(itemKey, itemValue)
    }
    removeItem = (itemKey: string) => {
        localStorage.removeItem(itemKey);
    }
}
export const localStorageKV = new LocalStorageKV();